@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';
@font-face {
  font-family: 'Segoe UI';
  src: url('assets/font/Segoe UI.woff') format('woff');
}

@font-face {
  font-family: 'Titillium Web';
  src: url('assets/font/TitilliumWeb-Regular.ttf');
}

@font-face {
  font-family: 'Titillium Bold';
  src: url('assets/font/TitilliumWeb-Bold.ttf');
}

@font-face {
  font-family: 'Urbanist';
  src: url('assets/font/Urbanist.ttf');
}

@font-face {
  font-family: 'Urbanist-Italic';
  src: url('assets/font/Urbanist-Italic.ttf');
}

* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  font-family: Segoe UI;
}

h1,
h2,
h3,
h4,
h5,
h6,
.heading {
  font-family: Titillium Bold;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Segoe UI;
}

.cursor-pointer {
  cursor: pointer !important;
}

.btn-disabled {
  cursor: not-allowed !important;
}

.alert .row {
  align-items: center;
}

.alert .row span {
  position: relative;
  bottom: 0;
  right: 20px;
}